import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import TextContainer from "../components/TextContainer/TextContainer"
// import textContainerStyles from "../components/TextContainer/TextContainer.module.sass"
import Img from "gatsby-image"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Map from "../components/Map/Map"
import Gallery from "../components/Gallery"
import Aside from "../components/Aside"
import Pull from "../components/Pull"
import SEO from "../components/seo"
import { Link } from "gatsby"

const shortcodes = { Map, Gallery, Aside, Pull }

const MemexPage = ({data}) => {
  const image = data.memex .frontmatter?.imageThumbnail?.childImageSharp?.fluid;

  return (
    <Layout section="memex">
      <div className="page-memex">
        <SEO 
          title="Building a Memex"
          image={image?.src}
        />
        <TextContainer>
          <h1>Building a Memex</h1>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{data.memex.body}</MDXRenderer>
          </MDXProvider>
          <form action="https://buttondown.email/api/emails/embed-subscribe/hyfen" method="post" target="popupwindow" onsubmit="window.open('https://buttondown.email/hyfen', 'popupwindow')" className="embeddable-buttondown-form signup">
            <input placeholder="Your email address" type="email" name="email" id="bd-email" />
            <input type="hidden" value="1" name="embed" />
            <input type="submit" value="Subscribe" />
          </form>
          <hr/>
          <h2>Newsletter Archive</h2>
          <p>(I haven't stopped working on the Memex! I just haven't done a newsletter in a while. But below are the archives:)</p>
          <div className="article-list">
            {data.newsletters.edges.map((item, i) => (
              <dl>
                <dt>
                  <Link to={`/memex/updates/${item.node.fields.slug}`}>
                    {item.node.frontmatter.title}
                  </Link>
                </dt>
                <dd>
                  {item.node.frontmatter.date}
                </dd>
              </dl>
            ))}
          </div>
        </TextContainer>
      </div>
    </Layout>
  )
}
export default MemexPage

export const pageQuery = graphql`
  query MyQuery {
    memex: mdx(fields: {slug: {eq: "memex-intro"}}) {
      id
      body
      frontmatter {
        title
        imageThumbnail {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 450, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    newsletters: allMdx(
      filter: {fields: {sourceName: {eq: "memex-updates"}}},
      sort:{fields:[frontmatter___date], order:DESC}
      ){
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            slug
            title
            date(formatString: "ll")
          }
        }
      }
    }
  }
`